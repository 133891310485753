<template>
    <div class="footprint">
        <Top name="我的足迹" back email></Top>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height:100%">

        
            <div class="foot_w">
                <van-list
                    class="list_side"
                    v-model="loading"
                    offset="50"
                    :finished="finished"
                    finished-text="没有更多了"
                    :error.sync="error"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                >
                    <van-cell v-for="item in list" :key="item.id" >
                        <div @click="listClick(item)">
                            <div class="index_list">
                                <span class="index_list_l">
                                    <img class="profilePhoto" :src="item.imgSrc">
                                    <span class="index_list_c">
                                        <!-- <div class="index_list_c_t">{{item.channel_name}}\{{item.job}}\{{item.lv}}级</div> -->
                                        <div class="index_list_c_t" v-if="item.onsale_type==1">{{item.channel_name}}\{{item.job}}\{{item.lv}}级</div>
                                        <div class="index_list_c_t" v-else-if="item.onsale_type==2">{{item.channel_name}}\{{item.item_name}}×{{item.item_num}}万</div>
                                        <div class="index_list_c_t" v-else-if="item.onsale_type==3">{{item.channel_name}}\{{item.item_name}}</div>
                                        <div class="index_list_c_b">
                                            <span class="index_list_c_b_t" :class="{xiajia : item.status == 7, shanchu: item.status == 4 || item.status == 5, yishouchu: item.status == 3}">{{item.status_name}}</span>
                                            <span class="index_list_c_b_b" v-if="item.status == 1 || item.status == 2 || item.status == 8">{{item.surplus_time}}</span>
                                        </div>
                                    </span>
                                </span>
                                <span class="index_list_r">
                                    <div class="index_list_r_t">{{item.server_name}}</div>
                                    <div class="index_list_r_b">￥{{item.price}}</div>
                                </span>
                            </div>
                        </div>
                    </van-cell>
                </van-list>
            </div>
        </van-pull-refresh>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Footprint',
    components: {
        Top,
    },
    data() {
        return {
            isLoading:false,
            loading: false,
            finished: false,
            error: false,
            list: [],
            total: 0,
            page: 1,
        }
    },
    methods: {
        onRefresh(){
            this.clearHttpRequestingList();
            this.error = false;
            this.loading = true;
            this.list = [];
            this.page = 1;
            if (this.finished) {
                this.finished = false;
                // this.onLoad();
            }else{
                this.finished = false;
                
            }
            this.onLoad();
        },
        //列表加载
        onLoad() {
            this.$api.account.myFootprint({
                page: this.page
            }).then(res => {
                if (res.code == 200) {
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((item) => {
                            this.list.push(item);
                        });
                        this.total = res.data.total;
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        if (this.page * 8 >= this.total) {
                            this.finished = true;
                        }
                        this.page ++;
                        //关掉错误提示
                        this.error = false;
                        this.list.forEach((item) => {
                            //获取上架中的状态样式
                            item.isActive = item.status == 2 ? false : true;

                            if(item.onsale_type!=1){
                                var imgSrc = '';
                                try{
                                    imgSrc = require('../../assets/images/itemicon/' + item.channel_id + '/' + item.icon_id + '.png');
                                }catch(e){
                                    imgSrc = require('../../assets/images/question.png');
                                }
                                
                                this.$set(item, 'imgSrc', imgSrc);
                                
                                return false 
                            }
                            //获取头像
                            if (item.sex == 200) {
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nan@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nan@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nan@2x.png');
                                }
                            }else{
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nv@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nv@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nv@2x.png');
                                }
                            }
                        });
                    }else{
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        this.finished = true;
                    }
                }else{
                    //加载状态结束
                    this.loading = false;
                    //错误提示
                    this.error = true;
                }
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err);
            });
        },
        //点击跳转到商品详情
        listClick(item) {
            // channel_id: this.formDate.channel_id
            // console.log(item)
            // return
            if(item.onsale_type != 2){
                let params = {
                goods_id: item.goods_id,
                channel_id:item.channel_id
            }, linkName = 'characterDetails';
            switch (item.status) {
                // 1：审核中 2：上架中 3：已出售 4：被取回 5：被删除 6：我的购买 7：被下架 8公示期
                case 1:
                    params.is_from = 'onSale';
                    break;
                case 2:
                    params.is_from = '';
                    break;
                case 8:
                    params.is_from = 'onSale';
                    break;
                case 3:
                    params.is_from = 'sold';
                    break;
                default: 
                    params = {};
                    linkName = '';

            }
            if(item.status == 4 || item.status == 7){
                // 被取回
                this.$toast({
                    duration: 1500,
                    message: '此订单已下架，无法查看详情'
                });
            }
            this.$router.push({
                name: linkName,
                query: params
            });
               
            }
        },
    }
}
</script>
